<template>
  <div class="common-table">
    <el-table
      :data="tableData"
      border
      height="90%"
      stripe
      v-loading="config.loading"
      :header-row-style="TableHeaderRowStyle"
      :header-cell-style="TableHeaderCellStyle"
      :row-style="TableRowStyle"
      :cell-style="TableCellStyle"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="40"> </el-table-column>
      <el-table-column prop="id" label="ID" width="180" sortable> </el-table-column>
      <el-table-column prop="contract_view" label="所属合同" width="180" sortable> </el-table-column>
      <el-table-column prop="additional_contract_date" label="追加时间" width="180" sortable> </el-table-column>
      <el-table-column prop="additional_contract_amount" label="追加金额" width="100"> </el-table-column>
      <el-table-column prop="added" label="添加" width="200"> </el-table-column>
      <el-table-column label="操作" width="170">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEdit(scope.row)" v-if="usertype.additional_contract_edit">编辑</el-button>
          <el-button size="mini" type="danger" @click="handleDelete(scope.row)" v-if="usertype.additional_contract_del">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination class="pager" layout="prev, pager, next" :total="config.total" :current-page.sync="config.page" @current-change="changePage">
    </el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      multipleSelection: []
    }
  },
  props: {
    tableData: Array,
    config: Object,
    usertype: Object
  },
  methods: {
    TableHeaderRowStyle() {
      return 'height:35px'
    },
    TableHeaderCellStyle() {
      return 'padding-bottom:4px;padding-top:4px;font-size:12px;font-weight:700;color:#000;'
    },
    TableRowStyle() {
      return 'height:35px'
    },
    TableCellStyle() {
      return 'padding-bottom:4px;padding-top:4px;font-size:12px;color:#000;'
    },
    //编辑
    handleEdit(row) {
      this.$emit('edit', row)
    },

    //删除
    handleDelete(row) {
      this.$emit('del', row)
    },

    //换页
    changePage(page) {
      this.$emit('changePage', page)
    },

    //行选择
    handleSelectionChange(val) {
      // console.log('child Selected rows:', val)
      this.$emit('getSelectedRows', val)
    }

    //设置特殊行（如已结单）的状态
    // tableRowClassName(row) {
    //   console.log('row......:', row)
    //   let rowIndex = row.rowIndex
    //   console.log('rowindex:', rowIndex)
    //   if (rowIndex === 0) {
    //     return 'warning-row'
    //   } else if (rowIndex === 1) {
    //     return 'success-row'
    //   }
    //   return ''
    // }
  }
}
</script>
